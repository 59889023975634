import React, {Component} from 'react';
import './App.css';
// import AddNumberRoot from "./components/AddNumberRoot";
// import DisplayNumberRoot from "./components/DisplayNumberRoot";
import Root from "./components/Root";

class App extends Component {
  state = {number:0}
  render(){
    return (
      <div className="App">
        {/* <h1>Root</h1> */}
        {/* <AddNumberRoot></AddNumberRoot>
        <DisplayNumberRoot></DisplayNumberRoot> */}
        <Root></Root>
      </div>
    );  
  }
}

export default App;
