import React, {Component} from "react";
import TopLeft from "../containers/TopLeft";
import TopMid from "../containers/TopMid";
import TopRight from "../containers/TopRight";
import News from "../containers/News";
import Group from "../containers/Group";
import Footer from "../containers/Footer";
export default class AddNumberRoot extends Component{
    render(){
      return (
        <div>
            <div className="flex">
                <div className="c-19 displayNone"><TopLeft/></div>
                <div className="c-62"><TopMid/></div>
                <div className="c-19 displayNone"><TopRight/></div>
            </div>
            <div className="flex">
                <div className="c-100"><News/></div>
            </div>
            <div className="flex">
                <div className="c-50">
                    <div className="displayTrue"><TopRight/></div>
                </div>
                <div className="c-50"><Group/></div>
            </div>
            <div className="flex">
            <div className="c-100"><Footer/></div>
            </div>
        </div>
        
      )
    }
  }