import React, {Component} from 'react';
// import CV from '../public/Hyemi Song_Resume_Public.pdf'
export default class TopRight extends Component {
    render(){
        return (
            <div className='padding-left-right-md centering line-h-md'>
                <div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-multiple-zero'></div>
                <div className='l-apple-box-half-zero'></div>
                <div className='h-1px'></div>

                    <div className='t-14 t-med'>
                        more herstory
                        <div className='t-18 t-reg'>
                            <a href= {process.env.PUBLIC_URL + '/HS_Resume_Public.pdf'} target="_blank" class='webintext'>
                            Curriculum Vitae
                            </a>
                        </div>
                    </div>

                    <div className='l-apple-box'></div>                    
                    <div className='t-14 t-med'>
                       Google Scholar
                        <div className='t-18 t-reg'>
                            <a href= {'https://scholar.google.com/citations?user=K4LQIcJVl2wC&hl=en'} target="_blank" class='webintext'>
                            Google Scholar
                            </a>
                        </div>
                    </div>
                    
                    <div className='l-apple-box'></div>
                    <div className='t-14 t-med'>
                       ORCiD
                        <div className='t-18 t-reg'>
                           <a href= {'https://orcid.org/0009-0004-5648-4478'} target="_blank" class='webintext'>
                            ORCiD
                            </a>
                        </div>
                    </div>

                    <div className='l-apple-box'></div>
                    <div className='t-14 t-med'>
                        emailing Hyemi
                        <div className='t-18 t-reg'>
                            <a href="mailto:hsong02@cs.umd.edu" target="_top" class='webintext'>
                            hsong02@cs.umd.edu
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}