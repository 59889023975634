import React, {Component} from 'react';
export default class TopLeft extends Component {
    render(){
        return (
            <div className='padding-md rotate line-h-sm t-18'>
                <div className='t-14 t-med'>
                        <div className='flex'>
                        thanks for the visit&nbsp; <img src= {process.env.PUBLIC_URL + '/smiley.svg'}/>
                        </div>
                        <div className='t-18 t-reg'>https://hyemisong.com</div>
                </div>
            </div>
        )
    }
}