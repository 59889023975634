import React from 'react'; 
const Header = () => ( 
    <div class="relative header">
        <div class="flex absolute centering-L50">
            <div>
                <div class="t-18 centering">https://</div>
                <span class="t-100 capitalize margin-right-md t-light">Hyemi</span>
            </div>
            <div>
                <div class="t-18 centering">.com</div>
                <span class="t-100 capitalize margin-right t-light">Song</span>
            </div>
        </div>
    </div>
) 
export default Header;
