import React, {Component} from 'react';
export default class Footer extends Component {
    render(){
        return (
            <div className='border-top bg-black txt-white centering'>
                <div className='padding-sm'>
                <div className='l-apple-box-multiple'></div>
                {/* <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div> */}
                    <div className='flex'>
                        {/* <div className='text'>Twitter</div>
                        <div className='text'>Google Scholar</div> */}
                    </div>
                    <div className='text'>© Copyright Hyemi Song. All Rights Reserved.</div>
                <div className='l-apple-box-multiple'></div>
                </div>
            </div>
        )
    }
}