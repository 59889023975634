import React, {Component} from 'react';
export default class Group extends Component {
    render(){
        return (
            <div className='border-left centering t-18 line-h-md'>
                <div className='padding-lg'>
                    <div className='l-apple-box-multiple'></div>
                    <div className='l-apple-box-multiple'></div>
                        <div className='section-title t-28'>Groups</div>
                        <div className='l-apple-box-doublehalf'></div>
                        <div className='text'>
                            <a href='https://www.cs.umd.edu/~varshney/index.html' target="_blank" class='webintext'>
                            UMD Graphics and Visual Informatics Laboratory
                            </a>
                        </div>
                        <div className='l-apple-box-doublehalf'></div>
                        <div className='text'>
                            <a href='https://hcil.umd.edu/' target="_blank" class='webintext'>
                            Human-Computer Interaction Lab (HCIL)
                            </a>
                        </div>
                        <div className='l-apple-box-doublehalf'></div>
                        <div className='text'>
                            <a href='https://bohyemian.com' target="_blank" class='webintext'>
                            Bohyemian Lab
                            </a>
                        </div>
                    <div className='l-apple-box-multiple'></div>
                    <div className='l-apple-box-multiple'></div>
                </div>
            </div>
        )
    }
}